import React from "react";
import { Row, Col, Typography, Table } from "antd";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import {
  informationCollectedConsumer,
  legalDefinitionsConsumer,
  columns,
  dataset,
} from "../../data/legal-data";
import CtaSecondary from "../../components/Buttons/secondary-cta";
import LegalList from "../../components/SpecialtyList/LegalList";
import ogImage from "../../images/global_assets/og-image.png";
import LegalStyles from "../../components/Layout/LegalStyles";
import Heading from "../../components-v2/Base/HeadingBuilder";

const { Title } = Typography;

const CPP = () => {
  return (
    <Layout className="consumer-privacy">
      <SEO
        title="SpotOn | Privacy &amp; Legal"
        description="At SpotOn, we respect your privacy and are committed to being upfront and transparent. Please review our terms of services and privacy policies for more information."
        image={`https://spoton.com/${ogImage}`}
      />
      {/* <Affix offsetTop={0}> */}
      <div style={{ marginTop: 25 }}>
        <CtaSecondary target="/legal" ctaTitle="Legal" reverseIcon />
      </div>
      {/* </Affix> */}
      <main className="consumer-privacy__data">
        <Row>
          <Col lg={16} sm={24}>
            <div>
              <Title level={1}>SpotOn Privacy Policy for Consumers.</Title>
              <Title level={5}>EFFECTIVE DATE: July 3, 2023</Title>
              <p>
                This Privacy Policy applies to the websites and services
                (together, the “Services”) provided by SpotOn Transact, LLC
                (“SpotOn”) . Merchants that are customers of SpotOn
                (“Merchants”) may provide certain information about you to
                SpotOn, and SpotOn may provide certain information about you to
                Merchants.
              </p>
              <p>
                This Privacy Policy covers only how SpotOn treats information
                relating to you, whether that information comes from your direct
                interaction with SpotOn or comes from Merchants with which you
                interact. Each Merchant has agreed with SpotOn to treat
                information relating to Consumers that it obtains from SpotOn or
                as a result of the Merchant’s use of SpotOn technology and
                Services as described in the Merchant Rules, which are available
                here. SpotOn has no responsibility for assuring that Merchants
                comply with the Merchant Rules, and you should consult each
                Merchant’s Privacy Policy to determine how that Merchant uses
                information relating to you.
              </p>
              <p>
                SpotOn respects the personal privacy of Consumers. This Privacy
                Policy describes how SpotOn uses the information of people who
                visit a SpotOn website (a “SpotOn Website”), use one or more of
                SpotOn’s mobile apps (“SpotOn Mobile”), or interact with
                SpotOn’s technology used at Merchants’ sites (such as tablets
                and point-of-sale devices). This policy also applies to
                information submitted or uploaded to SpotOn by a Consumer (e.g.,
                through Facebook, other social media sites or otherwise) or
                uploaded to SpotOn by a Merchant (each social media site or
                Merchant, a “SpotOn Access Portal”), and to information gathered
                by SpotOn as Consumers use any of the Services.
              </p>
              <p>
                This Privacy Policy does not apply to any use of your
                information by a Merchant, to any other services or websites
                that may be offered by a Merchant, or to any third-party sites
                that may be accessible from a SpotOn Access Portal or through
                the Services provided by SpotOn. To understand how any third
                party treats your information (including your information
                related to the use of SpotOn Services), you should contact such
                a third party or read its privacy policy. We encourage you to
                read this entire Privacy Policy before using a SpotOn Website,
                the SpotOn Mobile, a SpotOn device located at a Merchant, or
                submitting or uploading information through any Merchant or any
                other SpotOn Access Portal.
              </p>
              <p>
                Do not use a SpotOn Website, SpotOn Mobile, a SpotOn device
                located at a Merchant, or any other SpotOn Access Portal if you
                are unwilling to accept the terms and conditions of this Privacy
                Policy. By using SpotOn Access Portals or the Services, you
                agree to the terms of this Privacy Policy. When you submit
                information to SpotOn or use SpotOn’s services, you consent to
                the collection, storage, use, and disclosure of that information
                in accordance with this Privacy Policy. After reading this
                Privacy Policy, which is incorporated into and is a part of the
                User Terms of Service (“User Terms”), you will know:
              </p>
              <Title id="info" level={2}>
                1. Definitions
              </Title>
              <ul>
                {legalDefinitionsConsumer.map((item) => (
                  <li>
                    <p>{item.linkName}</p>
                  </li>
                ))}
              </ul>
              <Title id="info" level={3}>
                What Information SpotOn Collects From You
              </Title>
              <LegalList listData={informationCollectedConsumer} />
              <Title level={2}>2. Personal Information We Collect</Title>
              <Title id="info" level={3}>
                Why does SpotOn Collect Personal data?
              </Title>
              <p>
                SpotOn collects data from its customers to process activities.
                These data processing activities include (1) providing the
                SpotOn products and services, (2) monitoring, preventing and
                detecting fraudulent payment transactions and other fraudulent
                activity on the SpotOn platform, (3) complying with legal or
                regulatory obligations applicable to the financial sector to
                which SpotOn is subject, and (4) analyzing, developing and
                improving SpotOn products and services.
              </p>
              <p>
                SpotOn is a data processor where it facilitates payment
                transactions, points or rewards on behalf of and at the
                direction of our Merchants through or Sponsor Banks and
                Acquirers.
              </p>
              <Title id="info" level={3}>
                Active Information Collection
              </Title>
              <p>
                In its capacity as a service provider to Merchants, SpotOn
                actively collects information from our visitors to a SpotOn
                Website or SpotOn Mobile by asking specific questions, by
                allowing you to upload information through other tools (such as
                Facebook Connect or other social media site), and by permitting
                you to communicate directly with us through a SpotOn Website,
                SpotOn Mobile, via email or otherwise. If you sign up with
                SpotOn to use the Services directly with SpotOn or through any
                SpotOn Access Portal (including a Merchant or social media
                site), or if a Merchant signs you up for its services that use
                SpotOn technology, some of the information that you submit
                (directly or via an upload), or that is submitted for you by a
                Merchant, is required to register you to use the Services. Some
                of such information could identify you personally, such as your
                first and/or last name, telephone number, email address, zip
                code and birthdate. Such information is called “Personally
                Identifiable Information.” Some Services or programs may require
                you (or a Merchant on your behalf) to enter your demographic
                information and/or additional Personally Identifiable
                Information, such as your name (if not previously provided),
                gender, financial / credit card information or other
                information.
              </p>
              <p>
                SpotOn and Merchants may offer you, directly or through
                Facebook, Twitter, other social media sites, or other third
                parties, the opportunity to participate in activities related to
                the Services, such as responding to surveys, entering a contest
                or sweepstakes, playing games or providing information to our
                customer service representatives. If you choose to participate
                in such activities (when offered) SpotOn or Merchants may
                collect additional Personally Identifiable Information or other
                information from you with your consent.
              </p>
              <Title level={2}>3. Cookies and Tracking Technologies</Title>
              <p>
                The Site or our online Services may send a “cookie” to your
                computer. A cookie is a small file, typically of letters and
                numbers, downloaded onto a device such as a computer or mobile
                device when you access our websites. For more information about
                cookies or local storage, including how to see what cookies have
                been set and how to manage, block and delete them, see:
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="http://www.allaboutcookies.org/"
                >
                  http://www.allaboutcookies.org/
                </a>
                . A cookie cannot read data off your hard disk or read cookie
                files created by other sites. Cookies do not damage your system.
                Cookies allow us to recognize you as a user when you return to
                the Site or use the online Services using the same computer and
                web browser. We use cookies to identify which areas of the Site
                or online Services you have visited. We also may use this
                information to better personalize the content you see on the
                Site or online Services. SpotOn does not store unencrypted
                Personal Information in the cookies. We also do not link
                Non-Personal Information from cookies to your Personal
                Information.
              </p>
              <p>
                To help us optimize the Site or for marketing purposes, we may
                allow other authorized third parties to place or recognize
                unique cookies on your browser. Any information provided to
                third parties through cookies will be for enhancing your user
                experience by providing more relevant marketing. Third party
                services and tools we use, which may send cookies to users of
                the Site and may collect information from users.
              </p>
              <p>
                Some browsers may allow you to manage the storage of cookies on
                your device. If supported by your browser, you may set your
                browser to refuse all cookies (or, sometimes all third-party
                cookies) or to alert you when a cookie is placed. However, if
                you select these settings, you may be unable to access certain
                parts of the Site or the online Services. Unless you have
                adjusted your browser setting to refuse cookies, the Site and
                online Services will issue cookies. For more information about
                how to manage your cookies preferences, use the ‘help’ menu of
                your web browser or explore the customer support sections of
                your web browser. To opt-out of all cookies or certain
                advertising cookies, visit the company website for your browser
                for instructions.
              </p>
              <p>
                You can opt-out of receiving personalized ads from advertisers
                and ad networks that are members of the Network Advertising
                Initiative (“NAI”) or who follow the Digital Advertising
                Alliance (“DAA”) Self-Regulatory Principles for Online
                Behavioral Advertising using their respective opt-out tools. The
                NAI's opt-out tool can be found here:
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="http://www.networkadvertising.org/choices/"
                >
                  http://www.networkadvertising.org/choices/
                </a>
                . and the DAA's opt out tool can be found here:
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="http://www.aboutads.info/choices/"
                >
                  http://www.aboutads.info/choices/
                </a>
                .
              </p>
              <p>
                In addition, your mobile devices may offer settings that enable
                you to make choices about the collection, use, or transfer of
                mobile app information for online behavioral advertising (for
                example, Apple iOS’ Advertising ID and Google Android’s
                Advertising ID). Please note that opting out does not prevent
                the display of all advertisements to you.
              </p>
              <Title level={3}>Embedded Pixels</Title>
              <p>
                We may use embedded pixel technologies on selected pages on our
                Sites for the purposes of identifying unique user visits to the
                Site as opposed to aggregate hits. In addition, embedded pixels
                or other technologies may be used in emails to provide
                information on when the email was opened to track marketing
                campaign responsiveness; information collected using these
                technologies may be associated with your email address.
              </p>
              <Title level={3}>Flash Cookies</Title>
              <p>
                We may use flash cookies, also known as “local shared objects,”
                on the Site if it employs or in the future employs flash
                technology. Flash cookies are small files similar to browser
                cookies and are used to remember the site’s settings to
                personalize the look and feel of the site. Like normal cookies,
                flash cookies are represented as small files on your computer.
              </p>
              <Title level={3}>Embedded URLs</Title>
              <p>
                We may also use a tracking technique that employs embedded URLs
                to allow use of the Site without cookies. Embedded URLs allow
                limited information to follow you as you navigate the Site but
                are not associated with your Personal Information and is not
                used beyond the session.
              </p>
              <Title level={3}>Widgets</Title>
              <p>
                The Site may include widgets, which are interactive mini
                programs that run on our Site to provide specific services from
                another company (e.g., links to bookmarked sites). Some
                information, such as your email address, may be collected
                through the widget. Cookies may also be set by the widget to
                enable it to function properly. Information collected by this
                widget is governed by the privacy policy of the company that
                created it.
              </p>
              <Title level={3}>Loyalty Program Information Collection</Title>
              <p>
                After you register with SpotOn (directly or through a SpotOn
                Access Portal) or are registered by a Merchant, you may receive
                “Offers,” defined as special promotions or offers from one or
                more Merchants; may have such Offers sent to you automatically
                via email, mobile communications (e.g., SMS text messaging,
                mobile notifications), Facebook, Twitter or other social media;
                may be able to post those Offers on your own Facebook page to
                share with your friends; and/or may be able to use other tools
                offered by SpotOn. SpotOn may automatically keep track of all of
                those choices and associate them with the registration
                information you provided or that was provided for you by a
                Merchant, including your Personally Identifiable Information you
                (or a Merchant on your behalf) may have provided. SpotOn may
                also keep track of, and associate with your Personally
                Identifiable Information, activities you engage in offline that
                relate to the Services, such as which Offers you respond to (for
                example, by purchasing the goods or services that are the
                subject of the Offer) and loyalty rewards you earn from offline
                purchases, your reservations and appointments, your spending
                habits, and other services that SpotOn may make available to
                you. All of the information described in this paragraph is
                called “Merchant Participation Data.” SpotOn may use Merchant
                Participation Data for a number of purposes, including keeping
                track of rewards points (if offered by the Merchant),
                categorizing you with respect to your response to Offers and
                rewards programs of each Merchant, and marketing goods or
                services of Merchants and SpotOn as described above. SpotOn will
                offer you the option not to receive such marketing messages from
                SpotOn.
              </p>
              <p>
                If SpotOn permits certain Merchants with which (or by which) you
                are registered to do so, such Merchants may also keep track of
                Merchant Participation Data and use it as described above,
                including marketing their goods or services as described above.
                If a Merchant is given permission to use its Merchant
                Participation Data, SpotOn will require such Merchant to agree
                to offer you an option not to receive such marketing messages.
              </p>
              <p>
                In addition to the foregoing, SpotOn will have access to
                information regarding your registration with and participation
                in the Offers and loyalty programs offered by all Merchants for
                which, or by which, you are registered (“Program Participation
                Data”). Program Participation Data may be used by SpotOn to keep
                track of loyalty points that you have accrued with each Merchant
                SpotOn works with, which may allow you to check all of those
                programs you are enrolled in on one page of a SpotOn Website or
                SpotOn Mobile. SpotOn may also use Program Participation Data,
                combined with your Personally Identifiable Information, for
                purposes such as categorizing you with respect to various
                Merchants or types of Merchants, Offers and loyalty programs and
                for sending you marketing messages for goods or services of
                SpotOn or other Merchants or service providers. Program
                Participation Data may enable SpotOn to market third-party goods
                and services to you, as described above. SpotOn will offer you
                the option not to receive such marketing messages. To do so, you
                should use the link provided by SpotOn in the message to elect
                not to receive future marketing messages, or if you are using
                SpotOn Mobile, you can adjust the application’s notification
                settings.
              </p>
              <p>
                The online advertising industry provides websites through which
                you may opt out of receiving targeted ads from parties that
                participate in self-regulatory programs. You can access these
                and learn more about targeted advertising and consumer choice
                and privacy by visiting the{" "}
                <a
                  href="https://thenai.org/opt-out/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Network Advertising Initiative
                </a>{" "}
                and the{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://optout.aboutads.info/?c=2&lang=EN"
                >
                  Digital Advertising Alliance
                </a>
                . Please note that cookie-based opt-outs are not effective on
                mobile applications. However, you may opt-out of personalized
                advertisements on some mobile applications by following the
                instructions for{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://support.google.com/googleplay/android-developer/answer/6048248?hl=en"
                >
                  Android
                </a>
                ,{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://support.apple.com/en-us/HT202074"
                >
                  iOS
                </a>
                , and{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.networkadvertising.org/mobile-choice/"
                >
                  others
                </a>
                . You must separately opt out in each browser and on each
                device. Please note you will continue to receive transactional
                data and general ads.
              </p>
              <p>
                Many Internet browsers allow you to adjust your cookie
                preferences or delete existing cookies. If you set your browser
                to reject cookies, you should be aware that certain
                browser-based app or website features, promotions or services
                may not be available to you or may not function correctly.
              </p>
              <p>
                Certain Internet browsers may enable you to set "do not track"
                preferences to limit the collection of personally identifiable
                information about your online activities over time and across
                third-party websites or online services. Mercari’s systems do
                not respond to Internet browser "do not track" signals or
                similar mechanisms.
              </p>
              <Title level={3}>Third Party Information Collection</Title>
              <p>
                Currently, the SpotOn technology operates on SpotOn Websites,
                SpotOn Mobile, SpotOn devices at Merchants’ locations, may
                operate on Merchant’s websites, any Spoton.com URLs, and also
                operates on Facebook’s platform and other social media
                platforms. In the future, the SpotOn technology could operate on
                other social media or other locations. Depending on the choices
                you make when you register (or a Merchant registers for you),
                Offers may be sent to you through Facebook, Twitter or other
                social media, and additional social media applications may in
                the future be included with our services (e.g., geolocation
                information). Information related to you, your use of the SpotOn
                technology and the goods and services provided by SpotOn and
                Clients may be available to Facebook, Twitter and other third
                parties, such as the operators of various social media or other
                sites.
              </p>
              <p>
                The privacy policies of Merchants, social media and other sites,
                and sites that you may link to from sites that employ the SpotOn
                technology, are not under the control of SpotOn. SpotOn does not
                have any responsibility or liability for the use of your
                personal information by Merchants, Facebook, Twitter, other
                social media companies or any other third parties. SpotOn is
                also not responsible for the collection, storage or use of your
                information by any such third parties, for their privacy
                policies or for their security practices. If you would like
                information on any other party’s privacy policy, you should
                contact that party directly, and we encourage you to do so.
              </p>
              <p>
                This Privacy Policy is applicable only to information you submit
                to SpotOn (directly or through uploads authorized by you), that
                is submitted to SpotOn for you by a Merchant, or that SpotOn
                collects from the SpotOn technology or the Services. If you link
                from a SpotOn Website, SpotOn Mobile, any site or device
                operating the SpotOn technology, or the website of a Merchant
                that operates on SpotOn technology to the site or mobile app of
                any third party, your activities on those sites are subject to
                the separate privacy policies of the respective third parties
                (including Merchants) applicable to those particular sites or
                mobile apps. If a Merchant or other third party maintains a
                website or mobile app without a privacy policy, you should ask
                such Merchant or other third party about its privacy policies.
              </p>
              <p>
                SpotOn may hire third parties to assist our marketing efforts by
                placing SpotOn ads on other websites or search engines. We may
                also use third parties to display ads of unrelated companies on
                a SpotOn Website, SpotOn Mobile or any other SpotOn Access
                Portal. Such third party marketers or advertisers may use
                cookies to serve ads based on your prior visits to the SpotOn
                Website, SpotOn Mobile or other SpotOn Access Portal, and other
                sites or apps you have visited. SpotOn does not have any control
                over such cookies. You may be able to modify your browser
                settings to decline such cookies. If you decline cookies that
                affect your use of the SpotOn Website some features may not be
                available or may not operate properly. For example, if you
                access the SpotOn Services through Facebook, ads you may see
                framing the Services or presented in association with them are
                controlled by Facebook. You should review the Facebook Privacy
                Policy and/or contact Facebook for information regarding such
                advertising, including whether any such advertisements may
                solicit, or contain technology to collect, information about
                you.
              </p>
              <Title id="disclosure" level={2}>
                4. How we Use and Share Your Personally Identifiable Information
              </Title>
              <p>
                Except as otherwise stated, SpotOn may use information collected
                via any SpotOn Access Portal (including a SpotOn Website or
                SpotOn Mobile) or via the SpotOn technology to improve the
                content of any SpotOn Website, SpotOn Mobile, the Services (or
                similar or related services) to your preferences; to communicate
                information to you if you have requested it (such as your
                accrued rewards points); for our marketing and research purposes
                (including marketing other services provided by SpotOn,
                Merchants or third parties), and for any other purpose specified
                in this Privacy Policy. You can opt out of receiving such
                marketing messages through the “Settings” tab in the SpotOn App
                and by choosing the opt-out option provided in email messages.
              </p>
              <p>
                You provide Personally Identifiable Information when you
                register for the Services through any SpotOn Access Portal,
                including a SpotOn Website or SpotOn Mobile (for example, on the
                registration page or through an upload you authorize), and a
                Merchant may send SpotOn such information for you. SpotOn may
                combine any such information with Client Participation Data
                and/or Program Participation Data, as described in the section
                entitled “Program Information Collection.”
              </p>
              <p>
                SpotOn may provide Personally Identifiable Information you have
                provided to SpotOn (directly or through an upload), or that has
                been submitted to SpotOn by a Merchant on your behalf, to
                certain Clients with which (or by which) you are registered, and
                that have been permitted such information access by SpotOn. When
                and if permitted by SpotOn, such Merchants may use that
                information to send you messages, independently of the Services,
                about their goods and services and about the goods and services
                of other merchants. If you do not wish to receive such messages
                you may opt out of receiving them by choosing the opt-out option
                provided in such messages. All Merchants granted such permission
                by SpotOn will have agreed not to provide your Personally
                Identifiable Information to third parties except as described in
                the next section. Merchants have also agreed to abide by the
                rules and obligations under the CAN-SPAM Act of 2003 (15 U.S.C.
                § 103, et seq). SpotOn is not responsible for assuring that
                Merchants comply with these commitments.
              </p>
              <Title level={3}>
                How SpotOn may disclose information it obtains through a SpotOn
                Website, SpotOn Mobile or the Services{" "}
              </Title>
              <p>
                SpotOn will not disclose Personally Identifiable Information
                provided by you or by Merchants on your behalf, or that SpotOn
                collects in conjunction with the Services, to other third
                parties (i.e., to parties other than Merchants with which (or by
                which) an end user is registered) without consent, except as
                described below. Although SpotOn is not responsible for assuring
                that Merchants comply with their commitments, it does require
                each Merchant to agree to limit disclosure of Consumers’
                Personally Identifiable Information to the following:
              </p>
              <p>
                (A)to prevent fraud and strengthen our security, we may collect
                information and analyze information that helps us identify bad
                actors including both transactional data (such as amount,
                customer shipping address etc) and advanced fraud detection
                signals (device, IP and activity).
              </p>
              <p>
                (B) to an acquirer in the event of a sale or merger of
                Merchant’s business, provided that the acquirer agrees to
                maintain and use such data regarding Consumers in accordance
                with this Privacy Policy and the User Terms
              </p>
              <p>(C) as necessary to fulfill an order for a Consumer,</p>
              <p>
                (D) to service providers who need to know such information to
                provide services to Merchant consistent with their obligations
                and such service providers are obligated to keep such
                information confidential,
              </p>
              <p>
                (E) if required by law or subpoena, in response to an inquiry
                from law enforcement authorities or regulators, or
              </p>
              <p>
                (F) if Merchant believes the release of such information is
                necessary to address or prevent illegal or harmful activity.
              </p>
              <p>
                Other than as described above, SpotOn does not distribute
                Personally Identifiable Information to third parties, and
                Merchants have agreed not to do so either. SpotOn is not
                responsible for assuring that Merchants comply with that
                commitment.
              </p>
              <p>
                You understand that if you access the Services through Facebook
                or any other third party platforms, Facebook or the operator of
                such platforms will have access to your registration information
                and other information generated by your use of the Services,
                which may be aggregated with other information about you
                accessible to Facebook or the operator of such platforms, and
                may be stored and used by them, subject to the applicable
                privacy policy.
              </p>
              <p>
                SpotOn will not have any liability for disclosure of your
                information resulting from actions by Facebook or other social
                media companies or platforms, or from errors in transmission or
                the unauthorized intervention of third parties.
              </p>
              <Title level={3}>
                Information that does not identify your business or you
                personally
              </Title>
              <p>
                SpotOn may provide aggregate statistics or information about its
                customers, sales, trends, loyalty program usage, online traffic
                patterns, related information or other statistical de-identified
                or aggregated information to third parties, but these statistics
                or information will not include any Personally Identifiable
                Information (“Non-Identifying Information”). Non-Identifying
                Information is also used by SpotOn and/or third parties we
                contract with to troubleshoot, operate and improve SpotOn
                Websites, SpotOn Mobile and SpotOn technology, to assist us with
                marketing or ad placement, to cooperate with law enforcement
                activities or legal orders, to protect our rights (including
                enforcing the Merchant Terms and User Terms) or to address or
                prevent illegal or harmful activity. In addition, SpotOn may use
                all information submitted by Consumers that SpotOn collects
                about them that is Non-Identifying Information in any way SpotOn
                deems useful, including publishing statistics such as those
                described above to promote SpotOn or its Services.
              </p>
              <Title level={3}>Remarketing</Title>
              <p>
                Consumers consent to SpotOn using information that Consumers
                provide or that SpotOn collects about Consumers from their use
                of any SpotOn Website, SpotOn Mobile, the Services or resulting
                from use of the SpotOn technology, including their response to
                Offers from Merchants, their participation in the rewards
                programs of Merchants (if any) and/or other information, to send
                Consumers marketing messages related to goods or services SpotOn
                believes will be of interest to them. Such goods or services may
                be offered by SpotOn, Merchants, or other third parties. (See
                description above, under “Program Information Collection.”).
                Such marketing messages may be sent via email, mobile
                communications, Facebook, Twitter, other social media or
                otherwise, depending on how the Consumer has elected to receive
                Notes from various Merchants. Consumers can opt out of receiving
                such marketing messages through the “Settings” tab in the SpotOn
                App and by choosing the opt-out option provided in email
                messages. SpotOn will not buy, sell, rent, or share Consumers’
                consent to receive marketing messages.
              </p>
              <Title level={3}>Third-Party Analytics Services</Title>
              <p>
                SpotOn may use third-party analytics service providers to help
                us with our online services, such as Google Analytics, and
                Facebook. The analytics providers that administer these services
                use technologies such as cookies, web beacons, and web server
                logs to help us analyze how you use our online services. We may
                disclose your site-use information (including IP address) to
                these analytics providers, and other service providers who use
                the information to help us figure out how you and others use our
                online services.
              </p>
              <p>
                To learn more about how Google Analytics collects and processes
                data, and how to opt out, please visit{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="www.google.com/policies/privacy/partners"
                >
                  www.google.com/policies/privacy/partners
                </a>{" "}
                or{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://support.google.com/analytics/answer/181881?hl=en"
                >
                  https://support.google.com/analytics/answer/181881?hl=en
                </a>
              </p>
              <p>
                To learn more about how Facebook uses your data please visit{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/help/325807937506242/"
                >
                  https://www.facebook.com/help/325807937506242/
                </a>{" "}
                or log on to your Facebook account and access your settings. To
                understand more about Facebook advertising please see here{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/about/ads"
                >
                  https://www.facebook.com/about/ads
                </a>
                .
              </p>
              <Title level={3}>
                Choices end users have about how SpotOn uses their Information
              </Title>
              <p>
                SpotOn strives to provide Consumers with choices regarding the
                information they provide. SpotOn has created mechanisms to
                provide Consumers with the following control over their
                information:
              </p>
              <p>
                (i) You may view and modify the Personally Identifiable
                Information and/or Optional Information you submitted through a
                SpotOn Access Portal, or that was submitted to SpotOn on your
                behalf by a Merchant, as follows: you may modify the information
                you submitted when you registered, or when a Merchant registered
                you, by going to the applicable SpotOn Website or SpotOn Mobile.
                You may opt out of messages from SpotOn or revoke your consent
                to such messages, by turning off notifications for SpotOn Mobile
                or by choosing the opt-out option provided in any messages. If
                you want to stop receiving all messages through the Services,
                you will need to delete the SpotOn App from your device, visit
                each social media company through which the you chose to have
                the Services provided (e.g., Facebook, Twitter, etc.) and delete
                SpotOn from each such social media company, and choose the
                opt-out option provided in messages sent via email. Each
                Merchant has agreed to allow Consumers to stop receiving
                communications from it by allowing Consumers to opt out of such
                messages or to alter or revoke consents to communications from
                the Merchant. SpotOn is not responsible for assuring that
                Merchants comply with that commitment.
              </p>
              <p>
                SpotOn keeps track of Consumers’ transactions with Merchants or
                with services provided by SpotOn technology, as well as
                communications about Merchants that Consumers make through
                SpotOn technology (e.g., posting Offers to a Facebook page,
                sending Offers to friends). Consumers cannot delete information
                associated with such transactions and communications.
              </p>
              <p>
                (ii) Marketing messages from SpotOn will contain an option for
                the Consumer to choose not to receive such messages in the
                future. When and if marketing messages from Merchants or other
                or third parties are permitted, SpotOn intends to require the
                Merchant or other third party to agree to allow Consumers to
                choose not to receive such messages. SpotOn will not be
                responsible for assuring that Merchants or other third parties
                comply with such commitments.
              </p>
              <p>
                (iii) If a Consumer does not wish SpotOn to collect cookies, he
                or she may set the browser to refuse cookies, or to alert the
                user when cookies are being sent. Please note that some parts of
                the SpotOn Services may then be inaccessible or may not
                function.
              </p>
              <Title level={2}>
                5. Choices End Users Have About How SpotOn Uses Their
                Information
              </Title>
              <p>
                SpotOn strives to provide Consumers with choices regarding the
                information they provide. SpotOn has created mechanisms to
                provide Consumers with the following control over their
                information:
              </p>
              <p>
                (i) You may view and modify the Personally Identifiable
                Information and/or Optional Information you submitted through a
                SpotOn Access Portal, or that was submitted to SpotOn on your
                behalf by a Merchant, as follows: you may modify the information
                you submitted when you registered, or when a Merchant registered
                you, by going to the applicable SpotOn Website or SpotOn Mobile.
                You may opt out of messages from SpotOn or revoke your consent
                to such messages, by turning off notifications for SpotOn Mobile
                or by choosing the opt-out option provided in any messages. If
                you want to stop receiving all messages through the Services,
                you will need to delete the SpotOn App from your device, visit
                each social media company through which the you chose to have
                the Services provided (e.g., Facebook, Twitter, etc.) and delete
                SpotOn from each such social media company, and choose the
                opt-out option provided in messages sent via email. Each
                Merchant has agreed to allow Consumers to stop receiving
                communications from it by allowing Consumers to opt out of such
                messages or to alter or revoke consents to communications from
                the Merchant. SpotOn is not responsible for assuring that
                Merchants comply with that commitment.
              </p>
              <p>
                SpotOn keeps track of Consumers’ transactions with Merchants or
                with services provided by SpotOn technology, as well as
                communications about Merchants that Consumers make through
                SpotOn technology (e.g., posting Offers to a Facebook page,
                sending Offers to friends). Consumers cannot delete information
                associated with such transactions and communications.
              </p>
              <p>
                (ii) Marketing messages from SpotOn will contain an option for
                the Consumer to choose not to receive such messages in the
                future. When and if marketing messages from Merchants or other
                or third parties are permitted, SpotOn intends to require the
                Merchant or other third party to agree to allow Consumers to
                choose not to receive such messages. SpotOn will not be
                responsible for assuring that Merchants or other third parties
                comply with such commitments.
              </p>
              <p>
                (iii) If a Consumer does not wish SpotOn to collect cookies, he
                or she may set the browser to refuse cookies, or to alert the
                user when cookies are being sent. Please note that some parts of
                the SpotOn Services may then be inaccessible or may not
                function.
              </p>
              <Title level={2}>
                6. Retention and Storage of Personal Information
              </Title>
              <p>
                We retain your Personal Information for as long as necessary to
                fulfill the purpose for which it was collected and to comply
                with applicable laws. We use reasonable security precautions to
                protect your information while in storage.
              </p>
              <p>
                To determine the appropriate retention period for Personal
                Information, we consider the amount, nature, and sensitivity of
                the personal information, the potential risk of harm from
                unauthorized use or disclosure of your Personal Information, the
                purposes for which we process your personal information, whether
                we can achieve those purposes through other means, and
                applicable legal requirements.
              </p>
              <Title level={2}>7. How SpotOn Protects your Information</Title>
              <p>
                SpotOn works hard to protect the personal information of
                Consumers, using both technical and procedural methods to
                maintain the integrity and security of our databases. These
                include the use of SSL encryption, firewalls, administrative
                access level controls and employee training. Your payment method
                data is secured using PCI-DSS standards. Consumers should keep
                in mind, however, that no Internet transmission or data storage
                is ever completely secure or error-free. While SpotOn believes
                it uses commercially reasonable security procedures, it cannot
                guarantee the security of any information submitted by Consumers
                or information gathered about Consumers or information stored by
                SpotOn.
              </p>
              <p>
                Each Merchant has agreed to abide by this Privacy Policy. SpotOn
                is not responsible for Merchants’ compliance with this Privacy
                Policy or for Merchants’ security policies and practices. You
                acknowledge and agree that SpotOn has no responsibility or
                liability for the security policies or practices of any
                Merchant, for any security breach of any Merchant’s systems or
                databases, or for Merchants’ compliance with assurances made by
                the Merchant to SpotOn or to Consumers. SpotOn is not
                responsible for the actions of any third party, including but
                not limited to any Merchant, Facebook, other social media sites
                or other sites, with whom information about you may be shared or
                who may otherwise obtain such information.
              </p>
              <p>
                Each Merchant has agreed to abide by this Privacy Policy. SpotOn
                is not responsible for Merchants’ compliance with this Privacy
                Policy or for Merchants’ security policies and practices. You
                acknowledge and agree that SpotOn has no responsibility or
                liability for the security policies or practices of any
                Merchant, for any security breach of any Merchant’s systems or
                databases, or for Merchants’ compliance with assurances made by
                the Merchant to SpotOn or to Consumers. SpotOn is not
                responsible for the actions of any third party, including but
                not limited to any Merchant, Facebook, other social media sites
                or other sites, with whom information about you may be shared or
                who may otherwise obtain such information. The safety and
                security of information of each Consumer also depends on the
                Consumer. You should never share your SpotOn passwords, or any
                other password (including passwords you use with Merchants,
                Facebook or any other social media site), with anyone else, and
                should notify SpotOn, or any other relevant provider site if you
                believe your password security has been breached. Remember to
                log off of each SpotOn Website and SpotOn Mobile before you
                leave your computer or mobile device.
              </p>
              <Title level={2}>8. Links to Other Sites</Title>
              <p>
                The SpotOn Websites, SpotOn Mobile or other sites through which
                you may use the Services may contain links to other sites and/or
                resources over which neither SpotOn nor Merchants have any
                control. SpotOn makes no representations or warranties with
                respect to the content, ownership, or legality of any such
                linked sites. Links to third-party sites are provided solely as
                a convenience to you and should not be construed as an
                affiliation with or endorsement by SpotOn or any Merchant of
                such third parties or any content, items, or services on
                third-party sites. Access and use of such other sites, including
                the content, items, or services on those sites, solely at the
                risk of the user, and SpotOn has no responsibility or liability
                resulting from your access or use of such other sites. These
                linked sites may collect personal information from you when you
                enter their site. Any such collecting of information is not
                subject to the control of SpotOn. To ensure protection of
                privacy, you should always review the privacy policies of the
                sites you visit when linking from a SpotOn Website, SpotOn
                Mobile or any other site associated with the Services.
              </p>
              <p>
                You acknowledge and agree that SpotOn has no responsibility or
                liability for the availability of such external sites or
                resources, or for the content, advertising, products, or other
                materials available through such sites or resources.
              </p>
              <Title level={2}>9. Age of Consent</Title>
              <p>
                The SpotOn Website, SpotOn Mobile and the Services are only
                intended for persons 18 years of age or older. SpotOn will not
                knowingly collect Personally Identifiable Information from site
                visitors under the age of 13.If we learn that any information we
                collect has been provided by a child under the age of 13, we
                will take all reasonable measures to delete such information
                from its databases and to not use such information for any
                purpose (except where necessary to protect the safety of the
                child or others as required or allowed by law). If you have
                knowledge that a child 13 years of age or younger has submitted
                Personally Identifiable Information to us, please contact us and
                we will delete the Personal Information collected belonging to
                that child. You may contact us via email or by writing to us at
                the address below. Parents and guardians can also email us at
                privacy@spoton.com, but before any information is disclosed, the
                parent will be required to provide verification of his/her
                identity and authority related to any request. We will only send
                the information to the parent email address in the registration
                file.
              </p>
              <p>
                Merchants who use SpotOn’s Services may sell goods or services
                for children that are intended to be purchased by adults.
                Merchants have agreed that they will not knowingly collect such
                information. SpotOn is not responsible for assuring that
                Merchants comply with that commitment.
              </p>
              <Title level={2}>10. CAN-SPAM Compliance Notice</Title>
              <p>
                SpotOn fully complies with the federal CAN-SPAM Act. You can
                always opt out of receipt of further email correspondence from
                us.
              </p>
              <Title level={2}>11. CALIFORNIA Privacy Rights</Title>
              <p>
                This privacy policy describes how we may share your information
                for marketing purposes, as described above. If you are a
                California resident, the Shine the Light law permits you to
                request and obtain from us once per calendar year information
                about any of your personal information shared with third parties
                for their own direct marketing purposes, including the
                categories of information and the names and addresses of those
                businesses with which we have shared such information. To
                request this information and for any other questions about our
                privacy practices and compliance with California law, please
                contact us as explained below.
              </p>
              <Title level={2}>
                12. California Consumer Privacy Act (“CCPA”)
              </Title>
              <Title level={3}>Notice at Collection</Title>
              <p>
                At or before the time of collection, California residents may
                have a right to receive notice of our practices, including the
                categories of personal information to be collected, the purposes
                for which such information is collected or used, whether such
                information is sold or shared with third parties and how long
                such information is retained
              </p>
              <p>
                To the extent applicable, the following terms in this Section
                apply:
              </p>
              <p>
                This Privacy Policy for California residents supplements the
                information contained above in our general privacy policy and
                applies solely to visitors, users and others who reside in the
                State of California. Any inconsistent terms in any other
                sections of the Privacy Policy will be superseded by this
                Section for California residents.
              </p>
              <Title level={3}>Your Rights Under the CCPA</Title>
              <p>
                To the extent applicable to SpotOn, under the CCPA, California
                residents are granted the following rights:
              </p>
              <ol>
                <li>
                  <p>
                    The right to delete personal information businesses have
                    collected from them (subject to some exceptions);
                  </p>
                </li>
                <li>
                  <p>
                    The right to correct inaccurate personal information that
                    businesses have about them;
                  </p>
                </li>
                <li>
                  <p>
                    The right to know what personal information businesses have
                    collected about them and how they use and share it;
                  </p>
                </li>
                <li>
                  <p>
                    The right to opt-out of the sale of their personal
                    information;
                  </p>
                </li>
                <li>
                  <p>
                    The right to opt-out of the sharing of their personal
                    information for cross-context behavioral advertising;
                  </p>
                </li>
                <li>
                  <p>
                    The right to limit the use and disclosure of sensitive
                    personal information collected about them; and
                  </p>
                </li>
                <li>
                  <p>
                    The right to non-discrimination for exercising their CCPA
                    rights.
                  </p>
                </li>
              </ol>
              <p>
                When receiving a request, we will verify that the individual
                making the request is the resident to whom the Personal
                Information subject to the request pertains. California
                residents may exercise their rights themselves or may use an
                authorized agent to make requests to disclose certain
                information about the processing of their Personal Information
                or to delete Personal Information on their behalf. If you use an
                authorized agent to submit a request, we may require that you
                provide us additional information demonstrating that the agent
                is acting on your behalf.
              </p>
              <Title id="choices" level={3}>
                Categories of Personal Information We Collect
              </Title>
              <p>
                We may collect information as set forth above and also
                information that identifies you, your household or your device
                or is reasonably capable of being connected with or linked to
                you, your household or your device. “Personal Information” does
                not include public information available from government
                records, de-identified or aggregated information.
              </p>
              <Table columns={columns} dataSource={dataset} />
              <Title id="choices" level={3}>
                Retention{" "}
              </Title>
              <p>
                We retain your Personal Information for as long as necessary to
                fulfill the purpose for which it was collected and to comply
                with applicable laws. We use reasonable security precautions to
                protect your information while in storage.
              </p>
              <p>
                To determine the appropriate retention period for Personal
                Information, we consider the amount, nature, and sensitivity of
                the personal information, the potential risk of harm from
                unauthorized use or disclosure of your Personal Information, the
                purposes for which we process your personal information, whether
                we can achieve those purposes through other means, and
                applicable legal requirements.
              </p>
              <Title id="choices" level={3}>
                How We Use Your Information
              </Title>
              <p>
                See above for how we use your information. Except as otherwise
                stated in this Privacy Policy, we will not collect additional
                categories of Personal Information or use the Personal
                Information we collected for materially different, unrelated, or
                incompatible purposes without providing you notice.
              </p>
              <Title id="choices" level={3}>
                Requests to Know
              </Title>
              <p>
                You have the right to request that we disclose certain
                information to you about our collection and use of your Personal
                Information over the prior 12 months. Once we receive and
                confirm your verifiable consumer request, we will disclose to
                you:
              </p>
              <ul>
                <li>
                  <p>
                    The categories of Personal Information we collected about
                    you.
                  </p>
                </li>
                <li>
                  <p>
                    The categories of sources for the Personal Information we
                    collected about you.
                  </p>
                </li>
                <li>
                  <p>
                    The business or commercial purpose for collecting or selling
                    your Personal Information.
                  </p>
                </li>
                <li>
                  <p>
                    The categories of third parties with whom we share your
                    Personal Information.
                  </p>
                </li>
                <li>
                  <p>
                    The specific pieces of Personal Information we have
                    collected about you
                  </p>
                </li>
                <li>
                  <p>
                    If we sold/shared or disclosed your Personal Information for
                    a business purpose, which we do not do and will not do, two
                    separate lists disclosing:
                    <ul>
                      <li>
                        <p>
                          In the case of a sale/share, the categories of
                          Personal Information sold/shared about you and the
                          categories of third parties to whom the Personal
                          Information was sold/shared, by category of Personal
                          Information for each category of third parties; and
                        </p>
                      </li>
                      <li>
                        <p>
                          In the case of disclosure for a business purpose, the
                          categories of Personal Information that were sold,
                          shared, or disclosed for a business purpose and if
                          Personal Information has not been sold, shared, or
                          disclosed for a business purpose, that we did not do
                          so.
                        </p>
                      </li>
                    </ul>
                  </p>
                </li>
              </ul>
              <p>
                We are not required to (i) retain your Personal Information if
                collected for a single one-time transaction if, in the ordinary
                course of business, that information would not be retained and
                (2) re-identify or otherwise link any data that, in the ordinary
                course of business, is not maintained in a way that would be
                considered Personal Information.
              </p>
              <Title level={3}>Requests to Delete</Title>
              <p>
                You have the right to request that we delete any of your
                Personal Information that we collected from you and retained,
                subject to certain exceptions. Once we receive and confirm your
                verifiable consumer request, we will delete (and direct our
                service providers to delete) your Personal Information from our
                records, unless an exception applies, which we will disclose to
                you.
              </p>
              <p>
                We are not required to grant your deletion request if retaining
                the Personal Information is necessary for us or our service
                providers to:
              </p>
              <ol>
                <li>
                  <p>
                    Complete the transaction for which the Personal Information
                    was collected, fulfill the terms of a written warranty or
                    product recall, provide a good or service requested by you,
                    or reasonably anticipated within the context of our ongoing
                    business relationship with you, or otherwise perform a
                    contract between us and you.
                  </p>
                </li>
                <li>
                  <p>
                    Detect security incidents, protect against malicious,
                    deceptive, fraudulent or illegal activity; or prosecute
                    those responsible for that activity.
                  </p>
                </li>
                <li>
                  <p>
                    Debug to identify and repair errors that impact existing
                    intended functionality.
                  </p>
                </li>
                <li>
                  <p>
                    Exercise free speech, ensure the right of another consumer
                    to exercise that consumer’s right of free speech, or
                    exercise another right provided for by law.
                  </p>
                </li>
                <li>
                  <p>
                    Comply with the California Electronic Communications Privacy
                    Act.
                  </p>
                </li>
                <li>
                  <p>
                    Engage in public or peer-reviewed scientific, historical, or
                    statistical research in the public interest when the
                    deletion of the public information is likely to render
                    impossible or seriously impair the achievement of such
                    research, if the consumer has provided informed consent.
                  </p>
                </li>
                <li>
                  <p>
                    For solely internal uses that are reasonably aligned with
                    your expectations based on your relationship with us.
                  </p>
                </li>
                <li>
                  <p>Comply with a legal or regulatory obligation.</p>
                </li>
                <li>
                  <p>
                    Internally make otherwise lawful uses of your Personal
                    Information that are compatible with the context in which
                    you provided your Personal Information.
                  </p>
                </li>
              </ol>
              <Title id="choices" level={3}>
                Right to Correct
              </Title>
              <p>
                <b>How to Submit a Request</b>{" "}
              </p>
              <p>
                To request this information, please submit a verifiable consumer
                request to: Privacy@spoton.com and submit your request in the
                body of the email with the subject heading: “CCPA Consumer
                Request.”
              </p>
              <p>
                Only you, or someone legally authorized to act on your behalf,
                may make a verifiable consumer request related to your Personal
                Information. We are not required to provide you with Personal
                Information more than twice in a 12-month period.
              </p>
              <p>
                We will not be able to respond to your request or provide you
                with Personal Information if we cannot verify your identity or
                authority to make the request and confirm the Personal
                Information relates to you.
              </p>
              <p>
                We will try to respond to your request within forty-five (45)
                days of receipt of your written request. If we require more time
                (up to 90 days), we will inform you of the extension period in
                writing. We will deliver our written response by mail or
                electronically, at your option. Any disclosures we provide will
                only cover the 12-month period preceding the verifiable consumer
                request’s receipt. The response we provide will also explain the
                reasons we cannot comply with a request, if applicable. For data
                portability requests, we will select a format to provide your
                Personal Information that is machine-readable and should allow
                you to transmit the information from one entity to another
                entity without hindrance, specifically by electronic mail
                communication.
              </p>
              <p>
                We do not charge a fee to process or respond to your verifiable
                consumer request unless it is excessive, repetitive, or
                manifestly unfounded. If we determine that the request warrants
                a fee, we will tell you why we made that decision and provide
                you with a cost estimate before completing your request.
              </p>
              <Title id="privacy" level={3}>
                Opt-Out of Sale/Share Request
              </Title>
              <p>
                Once you make an opt-out request, we will wait at least twelve
                (12) months before asking you to reauthorize Personal
                Information sharing with third parties. However, you may change
                your mind and opt back into Personal Information sharing with
                third parties at any time by emailing us at privacy@SpotOn.com.
              </p>
              <p>
                Each Merchant has agreed to abide by this Privacy Policy. SpotOn
                We will only use Personal Information provided in an opt-out
                request to review and comply with the request. We will act upon
                your opt-out request within 15 days of receipt.
              </p>
              <Title level={3}>Non-Discrimination</Title>
              <p>
                We will not discriminate against you for exercising any of your
                CCPA rights. Unless permitted by the CCPA, we will not:
              </p>
              <ul>
                <li>
                  <p>
                    Deny you goods or services, except to the extent that
                    providing the Services requires the disclosure of Personal
                    Information that you have decided not to disclose or allow
                    us to use for the purpose of providing the Services.
                  </p>
                </li>
                <li>
                  <p>
                    Charge you different prices or rates for goods or services,
                    including through granting discounts or other benefits, or
                    imposing penalties.
                  </p>
                </li>
                <li>
                  <p>
                    Provide you a different level or quality of goods or
                    services.
                  </p>
                </li>
                <li>
                  <p>
                    Suggest that you may receive a different price or rate for
                    goods or services or a different level or quality of goods
                    or services.
                  </p>
                </li>
              </ul>
              <Title level={2}>13. NEVADA Privacy Rights</Title>
              <p>
                Nevada law (SB 220) permits customers in Nevada to opt-out of
                the sale of certain kinds of personal information. A sale under
                Nevada law is the transfer of this personal information to third
                parties for monetary consideration so these third parties can
                then resell or license the sold information. We do not sell your
                Personal Information to third parties as defined in Nevada law.
                If you are a Nevada resident and wish to opt-out of the sale of
                your personal information, should we change our practices in the
                future, you must send a request by email to privacy@SpotOn.com.
              </p>
              <Title level={2}>14. Notice to Virginia Users</Title>
              <p>
                We set forth above in our Privacy Policy the categories of
                personal data we process, the purpose for processing personal
                data, the categories of personal data shared, and the categories
                of third parties with whom personal data is shared.
              </p>
              <p>
                If you are a Virginia Consumer and would like to exercise your
                rights pursuant to the Virginia Consumer Data Protection Act
                (VCDPA), and any implementing regulations adopted thereunder,
                please send a request by email to privacy@SpotOn.com to submit a
                request.
              </p>
              <p>
                Virginia Consumers have the following rights, all subject to the
                meanings and exceptions set forth in the VCDPA:
              </p>
              <ul>
                <li>
                  <p>
                    To confirm whether we are processing your Personal Data and
                    request to access such data ("Right to Access").
                  </p>
                </li>
                <li>
                  <p>
                    That we correct inaccurate Personal Data we hold about you
                    (“Right to Correct”).
                  </p>
                </li>
                <li>
                  <p>
                    That we delete the Personal Data provided by you or obtained
                    about you ("Right to Delete").
                  </p>
                </li>
                <li>
                  <p>
                    To obtain a copy of the Personal Data previously provided by
                    you to us and, to the extent feasible, in a readily usable
                    format to allow data portability (“Right to Obtain”).
                  </p>
                </li>
                <li>
                  <p>
                    To opt- out of the processing of your Personal Data for the
                    purposes of targeted advertising (“Right to Opt-Out of
                    Targeted Advertising”).
                  </p>
                </li>
              </ul>
              <p>
                <b>VCDPA Appeals</b>
              </p>
              <p>
                Pursuant to the VCDPA, if, for any reason, you would like to
                appeal our decision relating to your request, you have the right
                to submit an appeal and can do so by sending a request by email
                to privacy@SpotOn.com to submit a request or call us toll-free
                at 877-814-4102 to submit a request. Please include your full
                name, the basis for your appeal, and any additional information
                to consider.
              </p>
              <Title id="california" level={2}>
                15. How Public Comments are Handled
              </Title>
              <p>
                Consumers may be allowed to post public comments, including
                comments about Merchants and their goods and services, on a
                Merchant’s Facebook page or on other social media. Such
                capability is offered by Facebook or another social media
                company, and is subject to the privacy policy of Facebook or
                such other social media company. We encourage you to review such
                privacy policies before submitting any such comments. Consumers
                may also be allowed to post similar comments on a SpotOn Website
                or SpotOn Mobile. Any messages posted in public access portions
                of Facebook, other social media sites a SpotOn Website or SpotOn
                Mobile may be read, saved, used and/or distributed by such
                social media companies, SpotOn, Merchants and any other users of
                the sites. Any such postings may be re-posted by any of such
                users on other websites, used in blogs, etc., and may appear in
                web searches. SpotOn cannot control who views information so
                posted or what they do with it, so care should be used when
                sending any personal information to any public access site.
                SpotOn also cannot control and is not responsible for the
                accuracy, truthfulness or tastefulness of any information posted
                by other users on Facebook or other social media sites, on a
                SpotOn Website, SpotOn Mobile or on any other public access
                site.
              </p>
              <Title level={2}>16. Amendments</Title>
              <p>
                You agree that this Privacy Policy is incorporated by reference
                into the User Terms. By visiting a SpotOn Website, SpotOn Mobile
                or using any of the SpotOn Access Portals, or using the Services
                (whether you registered for them or a Merchant registered you
                with SpotOn), you accept that any dispute over privacy is
                subject to this Privacy Policy and the User Terms, including but
                not limited to limitations on liability, disclaimers of damages,
                releases, waivers and application of the law of the State of
                Illinois. If you have any concern about privacy issues, please
                contact SpotOn as directed above.
              </p>
              <p>
                SpotOn reserves the right to update this Privacy Policy at any
                time. If this Privacy Policy is materially changed, the revised
                policy, or a notice that the policy has been revised, will be
                posted on the applicable SpotOn Website and SpotOn Mobile. You
                are responsible for periodically reviewing the Privacy Policy,
                and should do so frequently, especially before you provide any
                Personally Identifiable Information. When SpotOn changes this
                Privacy Policy, we will also revise the “last updated” date at
                the end of this Privacy Policy. If SpotOn updates this Privacy
                Policy, continued use of the Services (following the posting of
                the revised Privacy Policy) means that you accept and agree to
                the terms of the revised Privacy Policy. SpotOn recommends that
                you periodically review this Privacy Policy.
              </p>
              <p>
                In addition, the words “includes,” “including,” or any variation
                thereof as used in this Privacy Policy means “including, without
                limitation” and shall not be construed to limit any general
                statement that it follows to the specific or similar items or
                matters immediately following it.
              </p>
              <Title id="children" level={2}>
                17. Contacting SpotOn
              </Title>
              <Row>
                <Col>
                  <Heading level={3} injectionType={4} className="data">
                    If you have any questions regarding our privacy practices or
                    this privacy statement, or to request this privacy statement
                    in another format, please contact us at:
                  </Heading>
                  <p>
                    SpotOn Transact, LLC <br /> 100 California St, 9th Floor{" "}
                    <br /> San Francisco, CA 94111 <br /> 24/7 Phone:
                    <a href="tel:+18778144102">
                      &nbsp; 877-814-4102
                    </a> <br /> Facsimile:
                    <a href="tel:+18775216288"> &nbsp; 877-521-6288</a>
                    <br /> Email:
                    <a href="mailto:Support@SpotOn.com">
                      {" "}
                      &nbsp; Support@SpotOn.com
                    </a>
                  </p>
                  <p>
                    You may also contact the Merchant to which such questions,
                    comments or concerns relate. Please review this Privacy
                    Policy carefully. If you do not agree with this Privacy
                    Policy, your ultimate choice is not to use the Services.
                    Remember, by registering with SpotOn or using any part of
                    the Services (whether you registered for them or a Merchant
                    registered you with SpotOn), you accept and agree to the
                    privacy practices described in this Privacy Policy.
                  </p>
                  <p>
                    Each Client has agreed that this Privacy Policy is
                    incorporated by reference with the Merchant Terms, which
                    govern their relationship with SpotOn. SpotOn is not
                    responsible for assuring that Merchants comply with this
                    Privacy Policy.
                  </p>
                  <p>
                    You agree that that this Privacy Policy is incorporated by
                    reference into the User Terms. By visiting a SpotOn Website,
                    SpotOn Mobile or using any of the SpotOn Access Portals, or
                    using the Services (whether you registered for them or a
                    Merchant registered you with SpotOn), you accept that any
                    dispute over privacy is subject to this Privacy Policy and
                    the User Terms, including but not limited to limitations on
                    liability, disclaimers of damages, releases, waivers and
                    application of the law of the State of Illinois. If you have
                    any concern about privacy issues, please contact SpotOn as
                    directed above.
                  </p>
                  <p>
                    SpotOn reserves the right to update this Privacy Policy at
                    any time. If this Privacy Policy is materially changed, the
                    revised policy, or a notice that the policy has been
                    revised, will be posted on the applicable SpotOn Website and
                    SpotOn Mobile. You are responsible for periodically
                    reviewing the Privacy Policy, and should do so frequently,
                    especially before you provide any Personally Identifiable
                    Information. When SpotOn changes this Privacy Policy, we
                    will also revise the “last updated” date at the end of this
                    Privacy Policy. If SpotOn updates this Privacy Policy,
                    continued use of the Services (following the posting of the
                    revised Privacy Policy) means that you accept and agree to
                    the terms of the revised Privacy Policy. SpotOn recommends
                    that you periodically review this Privacy Policy.
                  </p>
                  <p style={{ paddingBottom: 60 }}>
                    In addition, the words “includes,” “including,” or any
                    variation thereof as used in this Privacy Policy means
                    “including, without limitation” and shall not be construed
                    to limit any general statement that it follows to the
                    specific or similar items or matters immediately following
                    it
                  </p>
                </Col>
              </Row>
            </div>
          </Col>

          <Col lg={8} sm={24}>
            <div className="aside">
              <Title level={3}>Related Documents</Title>
              <CtaSecondary
                target="/legal/merchant-privacy"
                ctaTitle="Merchant Privacy"
              />
              <CtaSecondary
                target="/legal/merchant-terms"
                ctaTitle="Merchant Terms"
              />
              <CtaSecondary
                target="/payments-terms.pdf"
                ctaTitle="Payments Terms"
              />
              <CtaSecondary target="/legal/user-terms" ctaTitle="User Terms" />
              <CtaSecondary
                target="/legal/point-of-sale-terms"
                ctaTitle="Point-of-Sale Terms"
              />
              <CtaSecondary
                target="/legal/website-terms"
                ctaTitle="Website Terms"
              />
              <CtaSecondary
                target="/legal/reserve-terms"
                ctaTitle=" Reserve Terms"
              />
              <CtaSecondary
                target="/legal/teamwork-terms"
                ctaTitle="SpotOn Teamwork Terms"
              />
            </div>
          </Col>
        </Row>
      </main>

      <LegalStyles />
    </Layout>
  );
};

export default CPP;
