import React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
// import './special-list.less';

import { colors } from '../../style-vars';
import icon from '../../images/svg/checkmark.svg';

const LegalList = (props) => {
  const { listData } = props;

  return (
    <ul className="special-list__legal">
      {listData.map((item, idx) => (
        <li key={`${idx.toString()}__${item}`}>
          <img src={icon} alt="" />
          <AnchorLink to={item.linkTo || ''} stripHash>
            <p>{item.linkName}</p>
          </AnchorLink>
        </li>
      ))}

      <li>
        <style jsx global>{`
          .special-list__legal {
            list-style: none;
            padding: 0;
          }

          .special-list__legal li {
            display: grid;
            grid-template-columns: 28px 1fr;
          }

          .special-list__legal li img {
            transform: translateY(8px);
            width: 16px;
          }

          .special-list__legal li p {
            color: ${colors.primary};
            font-weight: 800;
          }
        `}</style>
      </li>
    </ul>
  );
};

LegalList.propTypes = {
  listData: PropTypes.arrayOf(PropTypes.any),
};
LegalList.defaultProps = {
  listData: '',
};

export default LegalList;
